<template>
  <div class="maxBox">
    <div style="width:100%;margin:5px auto;display: flow-root;margin-bottom: 60px;">
      <div style="
            margin: 0px auto;
            display: flow-root;
            width: 95%;
          ">
        <van-list :loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          :offset="1"
          :immediate-check="false">
          <div v-for="item in ServiceList"
            :key="item.SOId"
            class="borderClass">
            <div class="van-card"
              @click="getOrder(item)">
              <div class="van-card__header"><a class="van-card__thumb">
                  <div class="van-image"
                    style="width: 100%; height: 100%;">
                    <img src="https://lyxnyapi.dexian.ren//upload/images/2022/9/1e41b2a6-bdd.png"
                      class="van-image__img"
                      style="object-fit: cover;border-radius: 8px;">
                  </div>

                </a>
                <div class="van-card__content">
                  <div style="line-height:30px;">
                    <div class="van-card__price-integer van-ellipsis"
                      style="font-weight: 600;color: black;font-size:16px;">服务机构：{{item.Name}}</div>
                    <div>
                      <span class="van-card__price-integer">
                        <van-icon name="manager" />{{item.Charger}}</span>
                    </div>
                    <div>
                      <span class="van-card__price-integer">
                        <van-icon name="phone" />{{item.TouchTel}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import {
  WxGetSerOrganList
} from "@/api/ylOrder.js";
import { setOpenId, getOpenId } from "@/utils/auth";
import Vue from 'vue';
import { Toast } from 'vant';
import { Dialog } from "vant";
Vue.use(Dialog);
Vue.use(Toast);
export default {
  data () {
    return {
      ServiceList: [],
      show: false,
      SerImg: [],
      EvalImgs: [],
      url: 'http://lycaljdapi.dexian.ren/',

      hidden: true, //没有更多数据了
      loading: true, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
    }
  },
  created () {
    // 获取并保存openid
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    console.log(this.$route.params);
    if (this.$route.params.OrgCode != '') {
      this.getServiceListCode()
    } else {
      this.getServiceList();
    }

  },
  methods: {
    onErrorImg: function (imgSrc) {
      return 'this.οnerrοr=null;this.src=' + '"' + imgSrc + '";';
    },

    getOrder: function (row) {
      var path = "/ylOrder/currentOrder/" + this.$route.params.Id + '/' + row.SOId + '/' + row.OrgCode
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
      // this.$router.push("/ylOrder/currentOrder/" + row + "/" + this.oCode);
    },

    getServiceList: function () {
      WxGetSerOrganList({ openID: getOpenId() }).then((res) => {
        this.loading = false;
        if (res.data.code == '0') {
          let rows = res.data.data; //请求返回当页的列表
          // 加载状态结束

          if (res.data.code == 0) {
            this.ServiceList = rows
            this.finished = true
          }

        } else {

        }
      });
    },
    getServiceListCode: function () {
      // if (this.$route.params.OrgCode == undefined) {
      //   var orgCode = ''
      // } else {
      //   var orgCode = this.$route.params.OrgCode
      // }
      WxGetSerOrganList({ orgCode: this.$route.params.OrgCode }).then((res) => {
        this.loading = false;
        if (res.data.code == '0') {
          let rows = res.data.data; //请求返回当页的列表
          // 加载状态结束

          if (res.data.code == 0) {
            this.ServiceList = rows
            this.finished = true
          }

        } else {

        }
      });
    },
  }
}
</script>

<style scoped>
.maxBox {
  /* background-color: #f9f9f9;
  min-height: 100%; */
}
/* .van-button__content {
  float: left !important;
} */
.borderClass {
  box-shadow: 0px 5px 17px 1px rgb(0 0 0 / 7%);
  border-radius: 5px;
}
.van-card {
  background-color: white;
  border-radius: 5px;
  margin-top: 8px;
}
.van-button__text {
  display: inline-grid !important;
}
.van-action-sheet {
  height: 500px !important;
}
.van-action-sheet__content {
  margin-bottom: 70px !important;
}
.van-sidebar-item--select::before {
  background-color: #ffc002 !important;
}
.title {
  margin: 10px 0;
  font-size: 17px;
  font-weight: 600;
  width: 70%;
  float: left;
}
.status {
  margin: 11px 0;
  font-size: 16px;
  width: 25%;
  float: right;
  text-align: center;
  color: #646566;
}
.buttonClass {
  width: 100%;
  text-align: right;
  margin-right: 10px;
  background-color: white;
}
.buttonClassType {
  margin: 5px;
  padding: 0 15px;
  border-radius: 5px;
  font-size: 16px;
}
</style>